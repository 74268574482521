<!-- Order -->
<template>
  <div class="card card-custom" >
      <div class="card-body p-0">
          <!--begin: Orders-->
          <div class="wizard wizard-1" id="kt_wizard_v1" data-wizard-state="step-first" data-wizard-clickable="true">
              <!--begin: Order Body-->
              <div class="row ">
                  <div class="col-xl-12">
                      <!--begin: Orders Form-->                        
                         <div class="pb-5" data-wizard-type="step-content" data-wizard-state="current">
                           <h3 class="mb-10 font-weight-bold text-dark"><i class="flaticon2-digital-marketing"></i> {{ title_text }}</h3>                          
                            <createQuotation></createQuotation>
                         </div>                       
                      <!--end: Orders Form-->
                  </div>
              </div>
              <!--end: Orders Body-->
          </div>
      </div>        
      <!--end: Orders-->
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import createQuotation from "@/components/OrderCreate/createQuotation.vue";
export default {
name : "Quotation Create",
    data() {
    return {
        title_text : "Create Quotation"
    }

},
mounted() {
      this.$store.dispatch(SET_BREADCRUMB, [
          {title: "Dashboard", route: "/dashboard"},
          {title: "Quotation", route: "/quotation/list"},
          {title: "Quotation Create"},
  ]);
  },
  components:{
    createQuotation,
  },
  created() {
    let quotationId = this.$route.params.quotation_id;
    if (parseInt(quotationId) > 0) {
        this.title_text = "Update Quotation";
    }
  }

}
</script>
